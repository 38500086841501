import Vue from "vue";
import Container from "./message.vue";

// 创建实例
const createInstance = (() => {
    const Layout = Vue.extend(Container);

    const instance = new Layout({
        el: document.createElement("div"),
    });

    // 添加dom
    document.body.appendChild(instance.$el);

    return () => instance;
})();

export function Message(options) {
    const instance = createInstance();
    const name = `message-${Date.now()}`;

    instance.add({
        name,
        ...options,
    });

    return () => {
        instance.close(name);
    }
}

// 错误
Message.error = function(message, options = {}) {
    Message({
        type: 'error',
        message,
        ...options,
    });
}

// 成功
Message.success = function(message, options = {}) {
    Message({
        type: 'success',
        message,
        ...options,
    });
}
