/*
 * @Author: Allan
 * @Date: 2021-12-30 18:16:51
 * @Describe: 请求封装
 */

import axios from "axios";
import config from "@/config/";

export const request = axios.create({
    baseURL: config.apiServer,
});

// 响应拦截
request.interceptors.response.use(
    function (response) {
        if (response.data.code === 200) {
            return response.data;
        }

        return Promise.reject(response.data);
    },
    function (error) {
        return Promise.reject(error);
    }
);
