<template>
    <div class="send-button-layout">
        <div :class="['button', account ? null : 'disabled']" @click="send">
            <span>{{timer ? count : $t('Register_6')}}</span>
        </div>
    </div>
</template>

<script>
import hmac from "crypto-js/hmac-sha256";
import { request, Message } from "@/common/";

export default {
    props: {
        // 类型
        type: {
            type: String,
        },
        // 账号
        account: {
            type: String,
        },
        // 是否需要人机验证
        captcha: {
            type: Boolean,
            default: true,
        },
        // 人机验证token
        captchaToken: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            // 定时器
            timer: null,
            // 计数
            count: 60,
        };
    },
    methods: {
        // 发送
        async send() {
            if (this.captcha && !this.captchaToken) {
                Message.error(this.$t('Register_44'));
                return;
            }

            if (this.timer || !this.account) return;

            try {
                const date = parseInt(Date.now() / 1000);
                // 加密
                const sign = hmac(
                    `${this.account}+++${date - date % 60}`,
                    "Zbxn5@RnZzq&GbFG8Ie0YMN$^xhl9Id7U8lLf3J8AkpBpQyU6pllsMPRv^b0qfAt^GpVJnd&GVQVD&Bs_WaxvoNK*A#f7yR^DB9d^fEuB!H%RxwQzZ1"
                );
                // 参数
                const params = {
                    lang: "en",
                    signStr: sign.toString(),
                    types: this.type,
                    vData: this.captchaToken,
                };

                // 请求地址
                let url;

                if (this.account.indexOf("@") != -1) {
                    params.mail = this.account;
                    url = "sms/mail/send";
                } else {
                    params.mobile = this.account;
                    url = "sms/mobile/send";
                }

                await request.post(url, params);

                // 倒计时
                this.timer = setInterval(() => {
                    this.count--;

                    if (!this.count) {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.count = 60;
                    }
                }, 1000);
            } catch (err) {
                Message.error(`Send failure - ${err.code || ''}`);
            }
        },
    },
};
</script>

<style lang="less" scoped>
// 验证码发送按钮
.send-button-layout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px;

    .button {
        min-width: 56px;
        display: block;
        height: 100%;
        background-image: linear-gradient(
            180deg,
            #c6c8ca 0%,
            rgba(0, 0, 0, 0) 100%
        );
        border-radius: 8px;
        overflow: hidden;
        padding: 1px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        span {
            padding: 0 8px;
            border-radius: 8px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #070d14;
            line-height: 1;
        }
    }

    .disabled {
        cursor: no-drop;
        opacity: 0.3;

        &:hover {
            opacity: 0.3;
        }
    }
}
</style>
