/*
 * @Author: Allan
 * @Date: 2021-09-22 14:21:09
 * @explain: 多语言入口
 * @Last Modified time: 2021-09-22 14:21:09
 */

import Vue from "vue";
import I18n from "vue-i18n";

Vue.use(I18n);

// 生成多语言
function generateMessage() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

export default new I18n({
  locale: window.localStorage.getItem('lang') || 'en',
  fallbackLocale: 'en',
  messages: generateMessage()
});
