<template>
    <div>
        <div class="login form-layout front-page" v-show="isShowFrontPage">
            <div class="item" @click="toggleWalletLogin">
                <img width="24" src="@/assets/images/wallet-icon.png" />
                <span class="name">Connect Wallet</span>
            </div>
            <!-- 分割线 -->
            <div class="division">
                <span>OR</span>
            </div>
            <div class="item" @click="hideFrontPage">
                <img src="@/assets/images/logo.png" width="36" />
                <span class="name">Sign in with MOBOX</span>
            </div>
        </div>
        <div class="login form-layout" v-show="!isShowFrontPage">
            <h5 class="title">Login To MOBOX</h5>
            <!-- 第三方登录 -->
            <div class="third-party">
                <!-- bsc钱包登录 -->
                <div class="item wallet" @click="toggleWalletLogin">
                    <img src="@/assets/images/wallet-1.png" />
                    <span class="text-1">Connect</span>
                    <span>Wallet</span>
                </div>
                <!-- 谷歌登录 -->
                <div class="item google" ref="google">
                    <img src="@/assets/images/google.png" />
                    <span>Google</span>
                </div>
                <!-- 更多 -->
                <div class="more-layout">
                    <div class="more">
                        <img src="@/assets/images/more.png" />
                    </div>
                    <ul class="flow-menu">
                        <li @click="facebookLogin">
                            <img src="@/assets/images/facebook.png" />
                            <span>Facebook</span>
                        </li>
                        <li @click="twitterLogin">
                            <img src="@/assets/images/twitter.png" />
                            <span>Twitter</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 分割线 -->
            <div class="division">
                <span>OR</span>
            </div>
            <!-- 表单 -->
            <div class="form">
                <!-- 手机或邮箱 -->
                <input-item v-model="account" :label="$t('Register_31')" :placeholder="$t('Register_32')" />
                <!-- 密码 -->
                <input-item v-model="password" :label="$t('Register_14')" :placeholder="$t('Register_15')" type="password" />
            </div>
            <!-- 注册和忘记密码 -->
            <div class="menu">
                <!-- <router-link :to="`/register/phone?redirect=${$route.query.redirect || ''}`">{{$t('Register_29')}}</router-link> -->
                <router-link to="/forgot-passsword">{{$t('Register_30')}}</router-link>
            </div>
            <!-- 提交按钮 -->
            <submit-button @click="accountLogin" :disabled="disabled">
                <span>{{$t('Home_7')}}</span>
            </submit-button>
        </div>
        <!-- 钱包登录 -->
        <wallet-login v-if="isShowWalletLogin" @close="toggleWalletLogin" />
    </div>
</template>

<script>
import hmac from "crypto-js/hmac-sha256";
import InputItem from "@/components/input";
import WalletLogin from "@/components/wallet-login";
import SubmitButton from "@/components/submt-button";
import loginmixins from "@/mixins/login";
import { request, Message } from "@/common/";
import config from "@/config/index";

export default {
    mixins: [loginmixins],
    components: {
        InputItem,
        WalletLogin,
        SubmitButton,
    },
    data() {
        return {
            // 显示前置页
            isShowFrontPage: true,
            loading: false,
            account: "",
            password: "",
            isShowWalletLogin: false
        };
    },
    methods: {
        // 隐藏前置页
        hideFrontPage() {
            this.isShowFrontPage = false;
        },
        // 账号登录
        async accountLogin() {
            this.loading = true;

            const now = Date.now() / 1000;
            // 加密
            const hex = hmac(
                `${this.account}_${now - (now % 60)}_${this.password}`,
                config.loginSecret
            );
            // 参数
            const params = {
                passwd: this.password,
                sign: hex.toString(),
                // ts: now,
            };

            // 判断是否是邮箱
            if (this.account.indexOf("@") !== -1) {
                params.mail = this.account;
            } else {
                params.mobile = this.account;
            }

            try {
                const { data } = await request.post("user/login", params);
                this.onSuccess(data.token);
            } catch (error) {
                Message.error(this.$t(`Error_${error.code}`));
            }

            this.loading = false;
        },
        // 初始化谷歌登录
        initGooleLogin() {
            window.gapi.load("auth2", () => {
                const auth2 = window.gapi.auth2.init({
                    clientId: config.googleKey,
                });

                auth2.attachClickHandler(this.$refs.google, {}, ({ vc }) => {
                    this.requestToken("oauth/google", {
                        code: vc.access_token,
                    });
                });
            });
        },
        // Facebook登录
        facebookLogin() {
            window.FB.login((res) => {
                this.requestToken("oauth/facebook", {
                    code: res.authResponse.accessToken,
                });
            });
        },
        // Twitter登录
        async twitterLogin() {
            const width = 600;
            const height = 700;
            const top = window.screen.height / 2 - height / 2;
            const left = window.screen.width / 2 - width / 2;
            const callbackUrl = "https://mobox.io/login/twitter.html";

            try {
                const { data } = await request.post(
                    "oauth/twitter/requestToken",
                    {
                        oauth_callback: callbackUrl,
                    }
                );

                window.addEventListener("message", ({ data }) => {
                    if (
                        origin === window.origin &&
                        data.name === "twitter-complete"
                    ) {
                        const { token, verifier } = data.message;

                        this.requestToken("oauth/twitter", {
                            oauth_token: token,
                            oauth_verifier: verifier,
                        });
                    }
                });

                window.open(
                    `https://api.twitter.com/oauth/authenticate?oauth_token=${data.requestToken}`,
                    "Twitter",
                    `width=${width},height=${height},top=${top},left=${left}`
                );
            } catch (error) {
                Message.error(this.$t(`Error_${error.code}`));
            }
        },
        // 钱包登录
        async toggleWalletLogin() {
            this.isShowWalletLogin = !this.isShowWalletLogin;
        },
    },
    computed: {
        disabled() {
            return !this.account || !this.password || this.loading;
        },
    },
    mounted() {
        this.$nextTick(this.initGooleLogin);
    },
};
</script>

<style scoped lang="less">
// 移动端
@media (max-width: 600px) {
    .wallet {
        .text-1 {
            display: none;
        }
    }
}

// 标题
.title {
    font-size: 25px;
    text-align: center;
    line-height: 1;
    margin: 30px 0 35px 0;
}

// 前置页
.front-page {
    img {
        max-width: 100%;
    }

    .item {
        height: 60px;
        background: #13181f;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        .name {
            line-height: 1;
            font-weight: bold;
            margin-left: 15px;
        }
    }
}

// 第三方登录
.third-party {
    display: flex;

    .item,
    .more {
        background: #13181f;
        border-radius: 8px;
        height: 55px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }

    .item {
        padding: 0 10px;
        margin-right: 11px;

        img {
            width: 20px;
            display: block;
        }

        span {
            font-weight: bold;
            font-size: 14px;
            margin-left: 5px;
            line-height: 1;
        }
    }

    .wallet {
        flex: 2;
    }

    .google {
        flex: 1;
    }

    .more-layout {
        position: relative;

        &:hover .flow-menu {
            display: block;
        }
    }

    .more {
        width: 55px;

        img {
            width: 9px;
        }
    }

    .flow-menu {
        display: none;
        z-index: 5;
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        right: 0;
        top: 55px;
        width: 155px;
        background: #1c222c;
        border-radius: 16px;
        box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.6);
        overflow: hidden;

        li {
            padding: 0 20px;
            height: 48px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #222832;
            }

            img {
                width: 23px;
            }

            span {
                font-size: 15px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.5);
                display: block;
                flex: 1;
                text-align: right;
            }
        }
    }
}

// 分割线
.division {
    margin-top: 27px;
    margin-bottom: 24px;
    position: relative;
    text-align: center;

    span {
        display: inline-block;
        padding: 0 12px;
        position: relative;
        z-index: 1;
        font-size: 12px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.5);
        background-color: #1c222c;
    }

    &::after {
        content: "";
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #323841;
    }
}

// 注册和找回密码
.menu {
    margin-top: 23px;
    margin-bottom: 41px;
    display: flex;

    a {
        color: #ffffff;
        font-size: 14px;
        text-decoration: underline;
        transition: opacity 0.2s;
        font-weight: 500;

        &:hover {
            opacity: 0.7;
        }
    }
}
</style>
