<template>
    <li>
        <div :class="['message-item', type]">
            <i v-if="type === 'error'" class="iconfont">&#xe8d9;</i>
            <i v-else class="iconfont">&#xe68b;</i>
            <span>{{ message }}</span>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        // 名称 - 唯一
        name: String,
        // 自动关闭时间
        timer: {
            type: Number,
            default: 3000,
        },
        // 类型
        type: {
            type: String,
            default: "error",
        },
        // 消息
        message: String,
    },
    data() {
        return {
            timeout: null,
        };
    },
    methods: {
        // 自动关闭
        countdown() {
            if (this.timer) {
                this.timeout = setTimeout(() => {
                    this.$parent.$parent.close(this.name);
                }, this.timer);
            }
        },
        // 清除计时器
        clearTimeout() {
            // 组建销毁的时候重复调用
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        },
    },
    mounted() {
        this.countdown();
    },
    beforeDestroy() {
        // 销毁定时器
        this.clearTimeout();
    },
};
</script>

<style lang="less" scoped>
.message-item {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 4px;
    line-height: 1;

    .iconfont {
        font-size: 20px;
    }

    span {
        font-size: 15px;
        line-height: 1;
        margin-left: 5px;
    }

    i,
    span {
        vertical-align: middle;
    }
}
</style>

<style lang="less">
.message-item {
    &.error {
        background: #fef0f0;
        color: #f56c6c;
    }

    &.success {
        background: #f0f9eb;

        i {
            color: #67c23a;
        }

        span {
            color: #555555;
        }
    }
}
</style>
