/*
 * @Author: Allan
 * @Date: 2021-12-30 14:06:50
 * @Describe: 路由配置
 */

import Login from "@/views/login";
// import Register from "@/views/register";
import ForgotPasssword from "@/views/forgot-passsword";
import Desktop from "@/views/desktop";

export default [
    {
        path: "/",
        component: Login,
    },
    // {
    //     path: "/register/:mode",
    //     component: Register,
    // },
    {
        path: "/forgot-passsword",
        component: ForgotPasssword,
    },
    {
        path: "/desktop",
        component: Desktop,
    },
    {
        path: "*",
        redirect: "/"
    }
];
