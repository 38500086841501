<template>
    <div class="forgot-passsword form-layout">
        <!-- 手机 -->
        <phone-input v-model="phone" @area-change="areaChange" />
        <!-- 验证码 -->
        <input-item
            :label="$t('Register_6')"
            :placeholder="$t('Register_7')"
            v-model="code"
        >
            <template slot="suffix">
                <send-code-button
                    type="MOBILE_PWD_FORGOT"
                    :account="areaCode + phone"
                    :captcha="false"
                />
            </template>
        </input-item>
        <!-- 密码 -->
        <input-item
            v-model="password"
            :label="$t('Register_14')"
            :placeholder="$t('Register_15')"
            type="password"
        />
        <!-- 确认密码 -->
        <input-item
            v-model="confirmPassword"
            :label="$t('Register_16')"
            :placeholder="$t('Register_15')"
            type="password"
        />
        <ul class="rules">
            <li
                v-for="item in rules"
                :key="item.message"
                :class="item.status ? 'success' : ''"
            >
                <span>{{ $t(item.message) }}</span>
            </li>
        </ul>
        <!-- 提交按钮 -->
        <submit-button
            class="button"
            :disabled="disabled || loading"
            @click="submit"
            >{{ $t("Home_48") }}</submit-button
        >
    </div>
</template>

<script>
import PhoneInput from "@/components/phone-input";
import InputItem from "@/components/input";
import SubmitButton from "@/components/submt-button";
import SendCodeButton from "@/components/send-code-button";
import { request, Message } from "@/common/";

export default {
    components: {
        PhoneInput,
        InputItem,
        SubmitButton,
        SendCodeButton,
    },
    data() {
        return {
            // 手机号
            phone: "",
            // 地区码
            areaCode: "",
            // 验证码
            code: "",
            // 密码
            password: "",
            // 确认密码
            confirmPassword: "",
            // 请求loading
            loading: false,
        };
    },
    computed: {
        // 密码规则
        rules() {
            return [
                {
                    message: "Register_18",
                    status: this.verifyLength,
                },
                {
                    message: "Register_37",
                    status: this.existCapitial,
                },
                {
                    message: "Register_38",
                    status: this.existNumber,
                },
                {
                    message: "Register_39",
                    status: this.notSpace,
                },
            ];
        },
        isPhone() {
            return this.phone && /^[0-9]*$/.test(this.phone);
        },
        // 长度验证
        verifyLength() {
            return this.password.length >= 8;
        },
        // 包含大写
        existCapitial() {
            return /^.*[A-Z]+.*$/.test(this.password);
        },
        // 存在数字
        existNumber() {
            return /^.*[0-9]+.*$/.test(this.password);
        },
        // 不存在空格
        notSpace() {
            return this.password && this.password.indexOf(" ") == -1;
        },
        // 按钮禁用状态
        disabled() {
            return !(
                this.isPhone &&
                this.password &&
                this.password === this.confirmPassword &&
                this.code.length === 6
            );
        },
    },
    methods: {
        // 地区改变
        areaChange(code) {
            this.areaCode = code;
        },
        // 提交
        async submit() {
            this.loading = true;

            const params = {
                verify_code: this.code,
                mobile: this.areaCode + this.phone,
                passwd: this.password,
            };

            try {
                await request.post("user/passwd/forgod", params);
                Message.success("Password changed successfully");
                window.location.href = "https://www.mobox.io/home";
            } catch (err) {
                if (err.code) {
                    console.log(err);
                    Message.error(this.$t(`Error_${err.code}`));
                }
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="less" scoped>
// 密码规则
.rules {
    padding: 10px 20px;
    background: rgba(255, 151, 69, 0.1);
    border-radius: 0 10px 10px 10px;

    li {
        font-size: 13px;
        line-height: 1;
        padding: 4px 0;

        &::before {
            vertical-align: middle;
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../assets/images/error.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
        }

        span {
            line-height: 1;
            vertical-align: middle;
            padding-left: 6px;
        }
    }

    // 成功
    .success {
        &::before {
            background: url(../assets/images/success.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }
}
.button {
    margin-top: 41px;
}
</style>
