<template>
  <div class="view">
    <a :href="`mobox-login://${token}`">
      <h4>登录桌面端</h4>
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        token: window.localStorage.getItem('token'),
      };
    },
    mounted() {
      if (!this.token) {
        this.$router.push('/?redirect=' + window.location.href);
      }
    }
  };
</script>

<style lang="less" scoped>
  .view {
    padding: 10px;
    text-align: center;

    a {
      color: #ffffff;
    }

    h4 {
      cursor: pointer;
    }
  }
</style>
