<template>
    <div class="input-item">
        <div class="label">{{ label }}</div>
        <div class="input">
            <!-- 头部 -->
            <slot name="prefix" />
            <input
                :type="inputType"
                :placeholder="placeholder || label"
                v-model="inputValue"
                @input="onInput"
            />
            <!-- 尾部 -->
            <slot name="suffix" />
            <!-- 密码输入框状态按钮 -->
            <div
                class="visible-status"
                v-if="type === 'password'"
                @click="toggleVisible"
            >
                <img v-if="isVisible" src="@/assets/images/visible.png" />
                <img v-else src="@/assets/images/invisible.png" />
            </div>
        </div>
        <!-- 消息 -->
        <div class="message" v-if="message">{{ message }}</div>
    </div>
</template>

<script>
export default {
    props: {
        // 输入框值
        value: String,
        // 标签名称
        label: String,
        // 输入框类型
        type: {
            type: String,
            default: "text",
        },
        // 提示
        placeholder: String,
        // 错误消息
        message: String,
    },
    data() {
        return {
            inputValue: this.value,
            inputType: this.type,
        };
    },
    methods: {
        // 切换状态
        toggleVisible() {
            this.inputType = this.isVisible ? "password" : "text";
        },
        // 输入框变化
        onInput({ target }) {
            const value = target.value.replace(/(^\s*)|(\s*$)/g, "");

            this.$emit("input", value);
            this.$emit("change", value);
        },
    },
    computed: {
        isVisible() {
            return this.inputType === "text";
        },
    },
};
</script>

<style scoped lang="less">
.input-item {
    margin-top: 30px;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    .label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 4px;
    }

    .input {
        position: relative;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.1);
        overflow: hidden;

        input {
            box-sizing: border-box;
            width: 100%;
            display: block;
            outline: none;
            height: 50px;
            margin: 0;
            padding: 0 15px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            background-color: transparent;
        }

        .visible-status {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0 15px;
            transition: opacity 0.2s;

            &:hover {
                opacity: 0.7;
            }

            img {
                width: 20px;
            }
        }
    }

    .message {
        position: absolute;
        left: 0;
        top: 100%;
        padding-top: 5px;
        font-size: 14px;
        text-align: left;
        font-size: 14px;
        color: #f56c6c;
    }
}
</style>
