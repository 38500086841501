<template>
    <div id="app">
        <header>
            <img src="@/assets/images/security.png" />
            <span class="title">URL verification:</span>
            <span class="url">https://www.mobox.io</span>
        </header>
        <!-- logo -->
        <div class="logo">
            <img src="@/assets/images/logo.png" />
            <H6>MOBOX</H6>
        </div>
        <!-- 路由 -->
        <div class="router">
            <router-view />
        </div>
        <!-- 底部信息 -->
        <div class="footer">
            <div>
                Copyright © 2021 MOBOX DIGITAL CO., LTD. All rights reserved.
            </div>
            <div class="links">
                <span @click="openService">{{ $t("Home_29") }}</span>| <span @click="openPrivacyPolicy">{{ $t("Home_30") }}</span>|
                <span @click="openContact">{{ $t("Home_31") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
    methods: {
        openService() {
            window.open(
                this.isZh
                    ? "https://www.mobox.io/home/docs/termofusezh.pdf"
                    : "https://www.mobox.io/home/docs/termofuseen.pdf"
            );
        },
        openPrivacyPolicy() {
            window.open(
                this.isZh
                    ? "https://www.mobox.io/home/docs/privatezh.pdf"
                    : "https://www.mobox.io/home/docs/privateen.pdf"
            );
        },
        openContact() {
            window.open("mailto:community@mobox.io");
        },
    },
    computed: {
        isZh() {
            return this.$i18n.locale.indexOf("zh") >= 0;
        },
    },
};
</script>

<style scoped lang="less">
// 移动端
@media (max-width: 700px) {
    #app {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .logo {
        padding-top: 100px;

        img {
            height: 50px;
        }
    }

    .router {
        margin: 38px auto 60px auto;
    }
}

// pc端
@media (min-width: 700px) {
    #app {
        width: 550px;
        margin: 0 auto;
    }

    .logo {
        padding-top: 150px;

        img {
            height: 55px;
        }
    }

    .router {
        margin: 58px auto 100px auto;
    }
}

// 头部信息
header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 50px;
    background-color: #21180d;
    text-align: center;
    line-height: 50px;

    span {
        font-size: 14px;
        font-weight: 500;
    }

    img,
    span {
        vertical-align: middle;
    }

    img {
        width: 14px;
        display: inline-block;
        margin-right: 5px;
    }

    .title {
        color: #8aff4a;
        margin-right: 5px;
    }

    .url {
        color: #ffffff;
    }
}

.logo {
    text-align: center;

    h6 {
        margin: 8px 0 0 0;
        font-weight: 900;
        font-size: 26px;
    }
}

.router {
    background: #1c222c;
    border-radius: 16px;
}

.footer {
    opacity: 0.5;
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 30px;

    span {
        cursor: pointer;

        &:last-child {
            color: #ffffff;
        }
    }
}
</style>
