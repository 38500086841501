<template>
    <div class="message">
        <transition-group name="message-transitioning" tag="ul">
            <message-item
                v-for="item in items"
                :key="item.name"
                :name="item.name"
                :type="item.type"
                :timer="item.timer"
                :message="item.message"
            />
        </transition-group>
    </div>
</template>

<script>
import MessageItem from "./message-item.vue";

export default {
    data() {
        return {
            items: [],
        };
    },
    components: {
        MessageItem,
    },
    methods: {
        add(options) {
            this.items.push(options);
        },
        close(name) {
            // name如果不存在则关闭全部
            if (name) {
                const list = this.items;

                for (let i = 0; i < list.length; i++) {
                    if (list[i].name === name) {
                        // 移除
                        list.splice(i, 1);
                        return;
                    }
                }
            } else {
                this.items = [];
            }
        },
    },
};
</script>

<style lang="less" scoped>
.message {
    z-index: 99999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    ul {
        position: relative;
        margin: 30px 0 0 0;

        li {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}

// 过度动画
.message-transitioning-enter-active {
    animation: top-move 0.4s;
}

.message-transitioning-leave-active {
    animation: top-move 0.4s reverse;
}

@keyframes top-move {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
</style>
