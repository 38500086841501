<template>
    <div class="phone-input">
        <input-item
            class="phone"
            key="phone"
            :label="$t('Register_25')"
            :placeholder="$t('Register_26')"
            @change="inputChange"
        >
            <template slot="prefix">
                <div class="area" @click="toggleShowArea">
                    <span>+ {{ areaCode }}</span>
                    <i class="iconfont">&#xe688;</i>
                </div>
            </template>
        </input-item>
        <!-- 选择区号 -->
        <div class="area-layout" :style="{ height: isShowArea ? '180px' : 0 }">
            <div class="area-list">
                <ul>
                    <li
                        v-for="(item, index) in areas"
                        :key="index"
                        @click="selectArea(item.code)"
                    >
                        <span>{{ item.contry }}</span>
                        <span>+{{ item.code }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import InputItem from "@/components/input";
import { request } from "@/common/";

export default {
    props: {
        // 输入框值
        value: String,
    },
    components: {
        InputItem,
    },
    data() {
        return {
            // 地区手机编号
            areas: [],
            // 当前选择的区域
            areaCode: null,
            // 显示选择地区
            isShowArea: false,
        };
    },
    methods: {
        // 切换地区显示状态
        toggleShowArea() {
            this.isShowArea = !this.isShowArea;
        },
        // 获取区域code
        async getAreaCode() {
            try {
                const { data } = await request.post("sms/mobile/code");
                const list = [];

                for (let key in data) {
                    list.push(data[key]);
                }

                this.areaCode = list[0].code;
                this.areas = list;
                this.$emit("area-change", this.areaCode);
            } catch (_) {
                console.log(_);
            }
        },
        // 选择区号
        selectArea(code) {
            this.areaCode = code;
            this.toggleShowArea();
            this.$emit("area-change", code);
        },
        // 输入框改变
        inputChange(value) {
            this.$emit("input", value);
        },
    },
    created() {
        // 获取验区域
        this.getAreaCode();
    },
};
</script>

<style lang="less" scoped>
.phone-input {
    margin-top: 30px !important;
}

/deep/ .phone {
    .input {
        display: flex;
    }

    input {
        flex: 1;
        box-sizing: border-box;
    }
}

// 区号
.area {
    margin: 8px 0 8px 10px;
    padding: 0 14px;
    box-sizing: border-box;
    min-width: 80px;
    background: #13181f;
    border-radius: 8px;
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;

    span {
        font-size: 14px;
        margin-right: 6px;
    }

    i {
        font-size: 16px;
    }
}

// 区号列表
.area-layout {
    height: 180px;
    overflow: hidden;
    box-shadow: 0px 6px 18px 0px rgb(0 0 0 / 60%);
    transition: height 0.4s;
    border-radius: 16px;
}

.area-list {
    box-sizing: border-box;
    padding: 5px 0;
    height: 100%;
    background: #1c222c;

    ul {
        height: 100%;
        overflow-y: scroll;
    }

    li {
        line-height: 40px;
        font-size: 15px;
        display: flex;
        padding: 0 25px;
        transition: background-color 0.2s;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: #222832;
        }
    }
}
</style>
