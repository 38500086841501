import { request, Message } from "@/common/";
import { validateRedirect } from '@/utils';

export default {
    methods: {
        // 第三方登录请求token
        async requestToken(url, params) {
            try {
                const { data } = await request.post(url, params);
                this.onSuccess(data.token);
            } catch (error) {
                Message.error(this.$t(`Error_${error.code}`));
            }
        },
        // 登录完成
        onSuccess(token) {
            const link = this.$route.query.redirect || 'https://www.mobox.io';
            // 重定向判断是否合法
            const res = validateRedirect(link);
            if(!res){
                return;
            }
            try {
                window.localStorage.setItem("token", token);
            } catch(_) {
                window.localStorage.clear();
                window.localStorage.setItem("token", token);
            }

            window.location.href = decodeURIComponent(link);
        },
    },
};