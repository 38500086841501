<template>
    <button class="button" @click="click" :disabled="disabled">
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        // 禁用状态
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit("click");
            }
        },
    },
};
</script>

<style scoped lang="less">
.button {
    display: block;
    margin: 0 auto;
    border: none;
    width: 190px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    background: linear-gradient(75deg, #0069ff 0%, #1b4ef5 100%);
    border-radius: 11px;
    color: #ffffff;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: no-drop;
        opacity: 0.2;
    }
}
</style>
