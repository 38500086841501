/*
 * @Author: Allan
 * @Date: 2021-12-30 14:01:20
 * @Describe: 路由
 */

import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

const router = new Router({
    base: 'login',
    mode: "history",
    routes,
});

export default router;
