/*
 * @Author: Allan
 * @Date: 2021-12-30 18:06:43
 * @Describe: 全局配置
 */

export default {
    // apiServer: 'http://192.168.5.189:9090/',
    apiServer: 'https://accountapi.mobox.io/',
    // 谷歌登录key
    googleKey:
        "684040100111-v55ja7oaa7r3239gq4kh3l24k3f8blcq.apps.googleusercontent.com",
    // 登录加密key
    loginSecret: "mm_KHk98d2bf35555da3486c97c1f9ceaad4867a6980f4bd5f44eaa8",
};
